import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {

  constructor() { }


  static buildFilter(...filters: { name: string, value: string | string[]; }[]): string {
    const serializeFilters = DatabaseService._deestructureArrayFilters(filters);
    const query = serializeFilters.length > 0 ? `?${serializeFilters.filter(filter => filter.value)
      .map(filter => `${filter.name}=${filter.value}`).join('&')}` : '';
    return query;
  }

  static setFilter(parameters: { name: string, value: string | string[]; }[],
    filter: { name: string, value: string | string[]; }): { name: string, value: string | string[]; }[] {
    const element = parameters.find(parameter => parameter.name === filter.name);
    const newParameters = parameters.slice();
    if (element) {
      element.value = filter.value;
    } else {
      newParameters.push(filter);
    }
    return newParameters;
  }

  static _deestructureArrayFilters(filters): { name: string, value: string | string[]; }[] {
    const valuesString = filters.filter(filter => !Array.isArray(filter.value));
    const valuesArray = filters.filter(filter => Array.isArray(filter.value));
    const totalFilter = valuesString;
    valuesArray.forEach(filter => totalFilter.push(...filter.value.map(value => ({ name: filter.name, value }))));
    return totalFilter;

  }

  static slugify(text: string) {
    return text.toString().toLowerCase()
      .replace(/\s+/g, '-')           // Replace spaces with -
      .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
      .replace(/\-\-+/g, '-')         // Replace multiple - with single -
      .replace(/^-+/, '')             // Trim - from start of text
      .replace(/-+$/, ''); // Trim - from end of text;
  }

  static unSlugify(text: string) {
    return text.toString().toLowerCase()
      .replace(/-/g, ' ')       // Replace spaces with -
      .replace(/_/g, ' ')       // Remove all non-word chars
      .replace(/  /g, ' ');       // Remove all non-word chars
  }

}
