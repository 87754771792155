import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Instrument, InstrumentDriver } from '../classes/instrument';
import { Settings } from '../classes/settings';
import { DatabaseService } from './database.service';

@Injectable({
  providedIn: 'root'
})
export class InstrumentService {

  constructor(private httpClient: HttpClient) { }

  getInstrumentsByClient(client: string): Observable<Instrument[]> {
    return this.httpClient.get<Instrument[]>(Settings.INSTRUMENTS_BY_CLIENT_URL.replace('{id}', client));
  }
  getAvailableInstruments(client: string): Observable<Instrument[]> {
    return this.httpClient.get<Instrument[]>(Settings.AVAILABLE_INSTRUMENTS_URL.replace('{id}', client));
  }
  getInstruments(...filter: { name, value; }[]): Observable<HttpResponse<Instrument[]>> {
    const query = DatabaseService.buildFilter(...filter);
    const url = `${Settings.ALL_INSTRUMENTS}${query}`;
    return this.httpClient.get<Instrument[]>(url, { observe: 'response' });
  }
  getInstrumentsDrivers(): Observable<InstrumentDriver[]> {
    return this.httpClient.get<InstrumentDriver[]>(Settings.INSTRUMENTS_DRIVERS);
  }
  addInstrumentsToClient(client: string, instruments: number[]) {
    return this.httpClient.post<any>(Settings.ADD_INSTRUMENTS_TO_CLIENT_URL.replace('{id}', client), { instruments });
  }
  addInstrument(name: string, data: string, driver: number, instrumentsconfigs: {}) {
    return this.httpClient.post<any>(Settings.ALL_INSTRUMENTS, { name, data, driver, instrumentsconfigs });
  }
  deleteInstrument(id_instrument: any) {
    return this.httpClient.delete<void>(Settings.INSTRUMENT_BY_ID.replace('{id}', id_instrument));
  }
  getConfigFormat(id_instrument: any) {
    return this.httpClient.get<any>(Settings.INSTRUMENTS_DRIVER_CONFIG.replace('{id}', id_instrument));
  }
  getInstrumentConfig(id_instrument: number) {
    return this.httpClient.get<any>(Settings.GET_ALL_INSTRUMENTS_CONFIG.replace('{id}', '' + id_instrument));
  }
  addInstrumentConfig(id_instrument: number, language: string, config: any) {
    return this.httpClient.post<any>(Settings.ADD_INSTRUMENT_CONFIG, { id: id_instrument, language, config });
  }
}
