import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Partner } from '../classes/partner';
import { Observable } from 'rxjs';
import { Settings } from '../classes/settings';
import { Client } from '../classes/client';
import { User } from '../classes/user';
import { DatabaseService } from './database.service';


@Injectable({
  providedIn: 'root'
})
export class GroupService {

  constructor(
    private httpClient: HttpClient,
  ) { }

  getPartners(): Observable<HttpResponse<Partner[]>> {
    return this.httpClient.get<Partner[]>(Settings.PARTNER_URL, { observe: 'response' });
  }

  getFilteredPartners(...filter: { name, value; }[]): Observable<HttpResponse<Partner[]>> {
    const query = DatabaseService.buildFilter(...filter);
    const url = `${Settings.PARTNER_URL}${query}`;
    return this.httpClient.get<Partner[]>(url, { observe: 'response' });
  }

  getClient(id: number): Observable<Client> {
    const url = `${Settings.CLIENT_URL}${id}/`;
    return this.httpClient.get<Client>(url);
  }

  getClients(...filter: { name, value; }[]): Observable<HttpResponse<Client[]>> {
    const query = DatabaseService.buildFilter(...filter);
    const url = `${Settings.CLIENT_URL}${query}`;
    return this.httpClient.get<Client[]>(url, { observe: 'response' });
  }

  getProviders(): Observable<HttpResponse<any[]>> {
    const url = `${Settings.PROVIDER_URL}`;
    return this.httpClient.get<any[]>(url, { observe: 'response' });
  }

  createGroup(group: any, type: string): Observable<any> {
    switch (type) {
      case User.Partner:
        return this.createPartner(group);
      case User.Client:
        return this.createClient(group);
    }
  }

  createPartner(partner: Partner): Observable<Partner> {
    const url = Settings.PARTNER_URL;
    return this.httpClient.post<Partner>(url, partner);
  }

  createClient(client: Client): Observable<Client> {
    const url = Settings.CLIENT_URL;
    return this.httpClient.post<Client>(url, client);
  }


  submitFile(file: FormData): Observable<any> {
    return this.httpClient.post<any>(Settings.UPLOAD_FILE_URL, file);
  }

  updateAppearance(id: number, appearance: Client[ 'appearance' ]): Observable<any> {
    const url = `${Settings.CLIENT_URL}${id}/`;
    return this.httpClient.patch<Client>(url, { appearance });
  }

}
